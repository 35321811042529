exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-bye-tsx": () => import("./../../../src/pages/bye.tsx" /* webpackChunkName: "component---src-pages-bye-tsx" */),
  "component---src-pages-dashboard-demo-tsx": () => import("./../../../src/pages/dashboard/demo.tsx" /* webpackChunkName: "component---src-pages-dashboard-demo-tsx" */),
  "component---src-pages-dashboard-plan-tsx": () => import("./../../../src/pages/dashboard/plan.tsx" /* webpackChunkName: "component---src-pages-dashboard-plan-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-open-tsx": () => import("./../../../src/pages/open.tsx" /* webpackChunkName: "component---src-pages-open-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-pro-tsx": () => import("./../../../src/pages/register-pro.tsx" /* webpackChunkName: "component---src-pages-register-pro-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-templates-feature-tsx": () => import("./../../../src/templates/feature.tsx" /* webpackChunkName: "component---src-templates-feature-tsx" */),
  "component---src-templates-pro-tsx": () => import("./../../../src/templates/pro.tsx" /* webpackChunkName: "component---src-templates-pro-tsx" */)
}

